@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;

.au_circle-indicator {
  display: flex;
  .percent {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    position: relative;
    transform: rotate(-90deg);
    circle {
      fill: transparent;
      transform: translate(50%, 50%);
    }
  }
  .number {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
