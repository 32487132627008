@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;
.upper-section-mobile-left {
  cursor: pointer;
}

.expandablemenudiv {
  max-height: 0;
  transition: max-height 500ms ease-out;
  overflow: hidden;
}

.fade-in {
  max-height: 187rem;
  transition: max-height 500ms ease-in;
}

.active-drop-icon {
  transform: rotate(180deg);
}

@include m.media('mobile580') {
  svg.drop-icon {
    height: 8px;
    width: 16px;
  }
  .mobile-dropdown-div {
    padding: 0;
    align-content: center;
    align-self: center;
  }
  .mobile-dropdown-span {
    padding: 0;
  }
  .mobile-dropdown-span button {
    padding: 0;
  }
  .benef-details-heading-container {
    padding-left: 1rem;
  }
  .section-mobile-left {
    width: 100%;
  }
  .add-payee-input {
    width: 100%;
  }
  .submit-btn {
    width: 100% !important;
  }
}
@include m.media('ipadproportrait') {
  svg.drop-icon {
    height: 8px;
    width: 16px;
  }
  .benef-details-heading-container {
    padding-left: 1rem;
  }
  .upper-section-mobile-left {
    display: inherit;
  }
  .section-mobile-left {
    width: 100%;
    margin-bottom: 1rem;
  }
  .add-payee-input {
    width: 100%;
  }
}
