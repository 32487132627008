@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;

.au-badge-container {
  padding: 0.25rem;
  border-radius: 0.25rem;

  .au-badge-icon {
    padding: 0.25rem;
  }
}
