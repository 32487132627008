@use '../../../styles/abstracts/colors' as c;

.range-slider {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  .active-range {
    position: absolute;
    height: 0.5rem;
    background: var(--primary-600);
    width: 29.8%;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    padding-right: 0.8125rem;
  }
}
.range-slider p {
  font-size: 1.625rem;
  font-weight: 600;
  padding-left: 1.875rem;
  color: var(--primary-600);
}
.range-slider input {
  -webkit-appearance: none !important;
  appearance: none;
  width: 100% !important;
  height: 0.5rem;
  background: var(--primary-600);
  border: none;
  outline: none;
  border-radius: 0.25rem;
}

.range-slider input.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
}

.range-slider input:focus {
  outline: none;
}
.range-slider input::-webkit-slider-thumb {
  z-index: 10;
  -webkit-appearance: none !important;
  width: 1.5rem;
  height: 1.5rem;
  background: c.$white;
  border: 1px solid var(--primary-600);
  border-radius: 50%;
  cursor: pointer;
  margin-top: calc(max((1rem - 1px - 1px) * 0.5, 0px) - 2rem * 0.5);
}

.range-slider input::-webkit-slider-runnable-track {
  height: 0.5rem;
  border: none;
  border-radius: 0.25rem;
  background: var(--neutrals-300);
  box-shadow: none;
}

.range-slider input::-webkit-slider-thumb:hover {
  background: var(--primary-600);
}

.range-slider input.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(var(--primary-600), var(--primary-600)) 0 /
      var(--sx) 100% no-repeat,
    var(--neutrals-300);
}

.range-slider input.slider-progress:hover::-webkit-slider-runnable-track {
  background: linear-gradient(var(--primary-600), var(--primary-600)) 0 /
      var(--sx) 100% no-repeat,
    var(--neutrals-300);
}

.range-slider input.slider-progress:active::-webkit-slider-runnable-track {
  background: linear-gradient(var(--primary-600), var(--primary-600)) 0 /
      var(--sx) 100% no-repeat,
    var(--neutrals-300);
}
