@use '../../../styles/abstracts/mixins' as m;
.au-linear-progress {
  .label-container {
    display: flex;
    flex-direction: row;
    @include m.media('mobile') {
      flex-direction: column;
    }
  }
}
