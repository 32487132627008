@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;

.file-upload {
  label {
    @apply block mb-1.5;
    color: var(--neutrals-700);
  }
  .input__wrapper {
    position: relative;
    width: 10rem;
    input {
      position: absolute;
      opacity: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
  }
  .file-list {
    display: inline-flex;
    flex-direction: column;
    row-gap: 0.75rem;
    color: var(--neutrals-700);
    margin-top: 1rem;
  }
  .upload-error {
    color: c.$red-500;
  }
}
