
.widget_header
{
    display: flex;
        padding: 0.5rem;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

       .wd{
        display: flex;
            align-items: center;
            gap: 0.75rem;
       }
}
.widget_body
{
    display: flex;
        padding: 0.5rem 0.75rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
        align-self: stretch;

        .widget_row{
            display: flex;
                justify-content: space-between;
                align-items: flex-start;
                align-self: stretch;

                .row_item{
                    flex: 1 0 0;
                }
                .row_item_last
                {
                    display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        flex: 1 0 0;
                }
        }
}