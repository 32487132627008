.accordian_container {
  width: 20rem;

  .rotate_icon_down {
    transform: rotate(180deg);
    transition: transform 500ms;
    margin: auto 0;
  }
  .rotate_icon_up {
    transform: rotate(360deg);
    transition: transform 500ms;
    margin: auto 0;
  }

  .static_container {
    margin-top: 0.75rem;
  }

  .dynamic_container {
    margin-top: 0.75rem;
    display: none;
  }

  .open_dynamic_container {
    display: block;
  }
}
