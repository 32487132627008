@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.popup-container {
  position: relative;
  display: inline-block;
  padding-left: 0.5rem;
}

.popup-menu {
  position: absolute;
  right: 0.5rem;
  top: 2.5rem;
  z-index: 999;
  background-color: c.$white;
  min-width: 16rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 0.1rem solid var(--neutrals-100);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}
.menu-item {
  align-items: center;
  justify-items: flex-start !important;
  .menu-icon {
    min-width: 1rem;
  }
}
