.common_css {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.25rem solid #fff;
  border-radius: 12.5rem;
  &:focus {
    box-shadow: 0 0.063rem 0.125rem 0 rgba(16, 24, 40, 0.05),
      0 0 0 0.25rem rgba(250, 199, 174, 0.5);
    border: 0.25rem solid var(--primary-200);
  }
}

$size: (
  'xs': 1.5rem,
  'sm': 2rem,
  'md': 2.5rem,
  'lg': 3rem,
  'xl': 3.5rem,
  '2xl': 4rem,
);

@each $key, $val in $size {
  .profile_container_#{$key} {
    @extend .common_css;
    width: $val;
    height: $val;
  }
}
