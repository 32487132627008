@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.navbar_container {
  position: relative;
  left: 0;
  display: flex;
  width: 13.5rem;
  padding: 1rem 0;
  height: 100vh;
  background-color: c.$white;
  border-right: 1px solid c.$gray-200;
  @include m.responsive('mobilesmall', 'mobile') {
    display: none;
  }
  @include m.responsive('mobilesmall', 'mobile') {
    &.--open {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      display: block;
    }
  }
  .nav-list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .rotate-arrow-down {
    transform: rotate(180deg);
    transition: transform 500ms;
  }
  .rotate-arrow-up {
    transform: rotate(360deg);
    transition: transform 500ms;
  }
  .admin-close {
    @include m.responsive('mobileAbove') {
      display: none;
    }
  }
}
