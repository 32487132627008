.banner-slider {
  width: 21.72rem !important; //intentional
  height: 6.79rem !important; //intentional
  border-radius: 0.56375rem;

  .title-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .subtitle-container {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
