.inner {
  i::before {
    font-size: 3rem;
  }
}
.mw-100 {
  max-width: 100px;
}
.mh-55 {
  min-height: 55px;
  height: auto;
}
