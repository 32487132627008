@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.overview_card_container {
  display: flex;
  gap: 1rem;
  @include m.media('MDPI') {
    flex-direction: column;
  }

  .overview_section {
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    background-color: c.$white;
    padding: 1rem;
    gap: 0.75rem;
    flex: 1;
  }

  .cards {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
  }

  .overview-card-container {
    width: 18rem;
    border-radius: 0.75rem;

    .banner-img {
      width: 18rem;
      height: 100%;
      cursor: pointer;
      border-radius: 10rem;
    }
    .card {
      width: 18rem;
      border: solid 1px c.$gray-200;
      border-radius: 0.75rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .overview_section_card_amount_details {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .overview_section_card_mask_eye_icon {
          &:focus {
            outline: none;
            box-shadow: none;
          }
          &:hover {
            background-color: c.$white;
          }
        }
      }

      .overview_section_card_view-btn {
        background-color: var(--primary-100);
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
      }
    }
  }

  .approval_section {
    padding: 1rem;
    border-radius: 0.75rem;
    background-color: c.$white;
    width: 17.5rem;
    max-width: 100%;

    @include m.media('MDPI') {
      width: 100%;
      height: 14rem;
    }
  }
}
