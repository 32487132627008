@use './../../../styles/abstracts/colors' as c;
@use './../../../styles/abstracts/mixins' as m;

.stepper {
  .stepper__nav {
    background-color: var(--neutrals-100);
    padding: 1.125rem;
    align-items: center;
  }
  .step {
    height: 34px;
    padding: 8px;
    border: 1px solid c.$white;
    border-radius: 50px;
    background: c.$white;
    flex: 0 0 auto;
    .step__status,
    .label__title {
      display: none;
    }
    &--active {
      color: var(--primary-600);
      border-color: var(--primary-600);
      background: var(--primary-50);
      .label__title,
      .label {
        display: flex;
      }
      .step__title {
        font-size: 0.719rem;
      }
    }
    &--todo {
      color: var(--neutrals-700);
    }
    &--done {
      .label__icon {
        color: c.$green-500;
      }
    }
    &--error {
      color: var(--error-700);
      .label__icon {
        color: var(--error-700);
      }
    }
  }
  .step__sub-steps {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    .sub-step {
      flex: 1;
      font-size: 0.75rem;
      line-height: 1.5;
      padding: 0.625rem;
      text-align: center;
      color: var(--neutrals-700);
      cursor: pointer;
      &--active {
        color: var(--primary-600);
        font-weight: 700;
        border-bottom: 2px solid var(--primary-600);
      }
      &--done {
        color: var(--neutrals-500);
        svg {
          color: c.$green-500;
        }
      }
      &--error {
        color: var(--error-700);
        svg {
          color: var(--error-700);
        }
      }
    }
  }
  @include m.media('mobileAbove') {
    .stepper__nav {
      min-width: 275px;
      background-color: c.$white;
      padding: 2rem 2rem 0;
      border-radius: 0.75rem;
      align-items: flex-start;
    }
    .step {
      height: auto;
      padding: 0px;
      border: none;
      border-radius: 0;
      background: c.$white;
      padding-bottom: 2rem;
      .step__title {
        display: flex;
        justify-content: space-between;
        font-size: 0.875rem;
        line-height: 1.5;
        font-weight: 500;
        cursor: pointer;
        .label {
          display: flex;
          align-items: center;
        }
      }
      .step__status,
      .label__title {
        display: block;
      }
      &--todo {
        .step__sub-steps {
          display: flex;
        }
      }
    }

    .step__sub-steps {
      position: relative;
      flex-direction: column;
      height: auto;
      overflow: hidden;
      .sub-step {
        font-size: 0.875rem;
        padding: 0;
        border: none;
      }
      &.hide {
        height: 0;
      }
    }
    .sub-step {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      column-gap: 0.75rem;
    }
    .stepper__close{
      button{
        svg{
          display: none;
        }
      }
    }
  }
  @include m.media('mobile') {
    .step {
      &.m-inactive {
        .step__sub-steps,
        .label__title {
          display: none;
        }
      }
    }
    .step__sub-steps {
      width: 100%;
      overflow: scroll;
      -ms-overflow-style: none;  
      scrollbar-width: none;  
      &::-webkit-scrollbar {
        display: none;
      }
      .sub-step{
        flex: 1 0 auto;
      }
    }
    .stepper__steps-container {
      flex: 1;
      justify-content: space-between;
      overflow-x: scroll;
      overflow-y: hidden;
      margin-right: 1.25rem;
      -ms-overflow-style: none;  
      scrollbar-width: none;  
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .stepper__close{
      position: relative;
      ::before{
        content: '';
        position: absolute;
        width: .5px;
        height: 100%;
        left: -.625rem;
        box-shadow: 2px 0 2px 0 c.$gray-500;
        opacity: .4;
      }
      button{
        border: none;
        padding: .5rem;
        .btn__text{
          display: none;
        }
      }
    }
  }
}
