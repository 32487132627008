@use '../../../styles/abstracts/colors' as c;

.dropdown.amount-dropdown {
  .dropdown__wrapper {
    .dropdown__input {
      border: none;
      padding: 0;
      box-shadow: none;
    }
    .dropdown__list {
      left: -1rem;
      .check-mark {
        display: none;
      }
    }
    .dropdown__chevron {
      color: var(--neutrals-500);
    }
    .dropdown__value {
      .option__text {
        display: none;
      }
    }
  }
}
