@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.dropdown {
  @include m.media('mobileAbove') {
    max-width: 320px;
  }
  label {
    color: var(--neutrals-700);
  }
  &.disabled {
    .dropdown__input {
      background-color: var(--neutrals-100);
      .dropdown__value,
      .dropdown__option {
        .option__text {
          color: var(--neutrals-400);
        }
      }
    }
  }
  .dropdown__wrapper {
    position: relative;
    cursor: pointer;
    &.expanded {
      .dropdown__list {
        display: block;
      }
      .dropdown__chevron {
        svg {
          transform: rotate(180deg);
        }
      }
      .dropdown__input {
        border-color: var(--primary-300);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
          0px 0px 0px 4px rgba(var(--primary-300), 0.5);
        &.dropdown__searchable {
          height: 2.875rem;
          overflow: hidden;
        }
      }
    }
  }
  .dropdown__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid #a3a5aa;
    padding: 0.625rem 0.875rem;
    background-color: c.$white;
    .dropdown__chevron {
      svg {
        transform: rotate(0deg);
        transition: transform 0.5s ease;
        color: var(--neutrals-500);
      }
    }
    .placeholder {
      color: var(--neutrals-300);
      display: flex;
      .option__icon + .placeholder-text {
        margin-left: 6px;
      }
      svg {
        color: var(--neutrals-500);
      }
    }
    .value {
      color: var(--neutrals-900);
    }
    &:focus {
      outline: none;
      border: 1px solid var(--primary-500);
      box-shadow: 0px 1px 2px rgb(16 24 40 / 5%),
        0px 0px 0px 4px rgb(var(--primary-300) / 50%);
    }
  }
  .dropdown__value {
    display: flex;
    gap: 0.75rem;
    .dropdown-tags {
      display: flex;
      gap: 0.25rem;
      flex-wrap: wrap;
    }
  }
  .dropdown__value,
  .dropdown__option {
    .option__value {
      display: flex;
      gap: 0.75rem;
      color: var(--neutrals-500);
    }
    .option__text {
      display: flex;
      flex-direction: column;
      row-gap: 2px;
      color: var(--neutrals-900);
      .secondary-text {
        color: var(--neutrals-500);
      }
    }
  }
  .dropdown__list {
    display: none;
    position: absolute;
    min-width: 100%;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid var(--neutrals-100);
    box-shadow: 0px 12px 16px -4px rgba(58, 51, 57, 0.08),
      0px 4px 6px -2px rgba(26, 29, 43, 0.03);
    background-color: c.$white;
    max-height: 320px;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    .dropdown__action {
      width: 100%;
      text-align: center;
      color: var(--primary-600);
      margin: 0.625rem 0;
      font-weight: 500;
    }
    .dropdown__option {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      justify-content: space-between;
      &:hover {
        background-color: var(--neutrals-50);
      }
      &[aria-selected='true'] {
        background-color: var(--neutrals-100);
        .checkbox {
          border-color: var(--primary-600);
          color: var(--primary-600);
        }
      }
      .check-mark {
        color: var(--primary-600);
      }
      .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 0.25rem;
        border: 1px solid var(--neutrals-400);
        background-color: c.$white;
        align-self: baseline;
        flex: 0 0 auto;
      }
      &:first-child {
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
      }
      &:last-child {
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
      }
    }
  }
  &.multiSelect {
    .dropdown__option {
      justify-content: flex-start;
    }
  }
}
