@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.account-summary-card-container {
  border: 0.063rem solid var(--neutrals-200);
  display: flex;
  justify-content: space-between;
}

.account-summary-content-container {
  align-items: center;
}
.account-summary-text {
  color: var(--neutrals-900);
}
.account-summary-text-container {
  line-height: 1.25rem;
  color: var(--neutrals-500);
}
