@use '../../../styles/abstracts/colors' as c;

.apply_for_offers_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
  overflow-x: auto;

  .apply_for_offers_container_img {
    border-radius: 0.5rem;
  }

  .offer_card {
    background: url('../../../assets/images/apply-for-offer.png') no-repeat;
    height: 17.8rem;
    width: 14.5rem;
    align-items: flex-start;
    border-radius: 0.95025rem;
    background-color: c.$white;
  }
  .offer_card_two {
    background: url('../../../assets/images/apply-for-loan-two.png') no-repeat;
    height: 17.8rem;
    width: 14.5rem;
    align-items: flex-start;
    border-radius: 0.95025rem;
    background-color: c.$white;
  }
}
