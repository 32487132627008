@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.back-button {
  display: block;
  padding-bottom: 1rem;

  .btn__text {
    color: var(--neutrals-700);
    font-size: v.$fs-14;
    font-family: v.$font-family;
  }
  button {
    border: 1px solid var(--neutrals-300);
  }
}
