@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.date-range {
  .rdrDateDisplayWrapper {
    background-color: c.$white;
  }

  .rdrMonthAndYearPickers select {
    background-color: var(--neutrals-100);
  }

  .rdrInputRanges {
    display: none;
  }

  .rdrStaticRange:hover .rdrStaticRangeLabel {
    background: var(--neutrals-100);
    color: var(--primary-600);
  }

  .rdrStaticRangeLabel {
    color: var(--neutrals-700);
  }

  .rdrDefinedRangesWrapper {
    border-right: solid 0.06rem white;
    padding-right: 1.5rem;
  }

  .web-view-date-range {
    display: block;

    @include m.media('ipadproportrait') {
      display: none;
    }
  }

  .mobile-view-date {
    display: none;

    @include m.media('ipadproportrait') {
      display: block;
    }
  }
}
