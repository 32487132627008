@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;
.banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 0.75rem;
  padding: 1.5rem;
  gap: 0.75rem;

  background: url('../../../assets/images/beneficiary-banner.svg') no-repeat
      left bottom,
    linear-gradient(
      92.3deg,
      rgba(255, 235, 225, 0.724932) 0.47%,
      c.$white 98.84%
    );

  .banner-info-container {
    max-width: 571px;
    @include m.media('mobile') {
      padding: 0.75rem 0 0.75rem 0;
    }
    .button-container {
      .btn--tertiary-gray {
        background-color: c.$white;
      }
    }
  }
}
