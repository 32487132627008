@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;

.input-otp {
  .input-otp__fields {
    .input__wrapper {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.5rem;
      padding: 0.375rem;
    }
    input {
      font-size: v.$fs-18;
      font-weight: 600;
      text-align: center;
      &:focus::placeholder {
        color: transparent;
      }
    }
  }
  ::placeholder {
    color: var(--neutrals-300);
    font-weight: 500;
  }
  .input-otp__separator {
    color: var(--neutrals-300);
    font-size: v.$fs-18;
    font-weight: 500;
  }
  .input-otp__text {
    color: var(--neutrals-500);
  }
  .otp-input__label {
    font-weight: 500;
    color: var(--neutrals-700);
  }
  &--error {
    .input__wrapper {
      border-color: c.$red-400;
    }
    .input-otp__text {
      color: c.$red-600;
    }
  }
  &--success {
    .input__wrapper {
      border-color: c.$green-400;
    }
    .input-otp__text {
      color: c.$green-600;
    }
  }
  &.input-otp--2x {
    .input-otp__fields {
      column-gap: 0.75rem;
      .input__wrapper {
        width: 5rem;
        height: 5rem;
      }
      input {
        font-size: 3rem;
      }
    }
    .input-otp__separator {
      width: 1.5rem;
      font-size: 3.5rem;
    }
  }
}
