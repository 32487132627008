@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.manage_cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.75rem;
  background: c.$white;

  .manage_cards_title_section {
    padding: 1rem 1rem 0;
  }

  .manage_cards_scrollable {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;

    .manage_cards_card_section {
      display: flex;
      flex-direction: row;
      gap: 0.75rem;

      .manage_card_link_card {
        min-width: 18.5rem;
        width: 100%;
      }
    }
  }
}

.manage_card_link_card {
  min-width: 18.5rem;
  width: 100%;
}

.manage_cards_scrollable::-webkit-scrollbar {
  display: none;
}
