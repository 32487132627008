@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.payment-status-container {
  padding: 2rem;
  background-color: var(--neutrals-100);

  .payment-status-details-container {
    margin-top: 1rem;
    padding: 1.5rem;
    max-width: 46.875rem;
    width: 100%;
    background-color: c.$white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;

    .actionBtnClass {
      display: flex;
      flex-flow: row-reverse;
    }

    .status-type {
      gap: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .transaction-status {
        display: flex;
        flex-direction: column;
        align-items: center;
        .status-text {
          text-align: center;
        }
      }
    }
  }
}
