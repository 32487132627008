@use '../../../styles/abstracts/colors' as c;

.broadcast-message {
  display: flex;
  gap: 0.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  & .icon {
    margin-right: 0.2rem;
  }
  &--sm {
    max-width: 20.5rem;
    font-size: 0.875rem;
  }

  &--md {
    max-width: 30rem;
    font-size: 0.875rem;
  }
}
