@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.account-card-container {
  background-color: var(--neutrals-25);
  border: 1px solid var(--neutrals-300);
  width: 50%;
  border-radius: 0.5rem;
}
@include m.media('ipadproportrait') {
  .account-card-container {
    width: 100%;
  }
}
@include m.media('mobilesmall') {
  .account-card-container {
    width: 100%;
  }
}
.account-card-heading {
  color: var(--neutrals-800);
}
.account-card-description {
  color: var(--neutrals-800);
}
