@use '../../../styles/abstracts/mixins' as m;
@use '../../../styles/abstracts/colors' as c;

.auth-step {
  width: 344px;
  @include m.media('mobile') {
    width: auto;
  }
  .auth-step__title {
    color: c.$purple;
    margin-bottom: 0.25rem;
  }
  .auth-step__sub-title {
    color: c.$darkGray;
  }

  @include m.media('desktop') {
    padding: 0;
  }
}
