@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.offer_container {
  display: flex;
  justify-content: center;

  .offer_container_card {
    width: 40.49rem;
    border-radius: 0.5rem;
    position: relative;
    cursor: pointer;

    img {
      width: 40.49rem;
      object-fit: cover;
      border-radius: 0.5rem;
    }

    .offer_container_card_button {
      position: absolute;
      right: 2rem;
      top: 1.9rem;
    }

    .offer_container_card_close_badge {
      border: 0.064rem solid var(--neutral-300);
      background-color: c.$white;
      cursor: pointer;
      position: absolute;
      z-index: 9;
      top: -0.5rem;
      right: -0.5rem;
      padding: 0 !important;
    }
  }
}
