@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.payment_services_container {
  display: flex;
  padding: 1rem 1rem 1.5rem;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.75rem;
  background-color: c.$white;

  .payment_services_title_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .payment_service_cards {
    display: flex;
    gap: 2rem;
    padding-right: 1rem;
    cursor: pointer;

    .ntb_payment_cards {
      display: flex;
      flex-wrap: wrap;
      row-gap: 2rem;

      @include m.media('mobile') {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 2rem;
        column-gap: 2rem;
      }

      .payment_service_card {
        justify-content: flex-end;
        width: 9.5rem;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .payment_service_card_roundbox {
          background-color: var(--primary-100);
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          padding: 0.75rem;
        }
      }

      .make-disable {
        opacity: 0.6;
        cursor: default;
        pointer-events: none;
      }

      .payment_service_card:hover {
        background-color: var(--primary-100);
      }
    }
  }
}
