@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.admin_nav_container {
  z-index: 100;
  background-color: c.$purple;
  display: flex;
  height: 5rem;
  width: 100%;
  padding: 0px 1rem;
  .home-btn{
    &:hover{
      background-color: transparent;
    }
  }
 
  .nav_left_container {
    display: flex;
    align-items: center;
    .home-icon {
      @include m.responsive('mobilesmall', 'mobile') {
        display: none;
      }
    }
    .hamburger-icon {
      @include m.responsive('mobileAbove') {
        display: none;
      }
    }
    .search-bar {
      @include m.responsive('mobilesmall', 'mobile') {
        display: none;
      }
    }
  }
    .right_content 
    {
      margin-left: auto;
        align-items: center;
        display: flex;
      .list_wrapper {
          display: flex;
          align-items: center;

          li {
              list-style-type: none;
            }
        }

                .profile_wrapper {
                  z-index: 10;
                  display: flex;
                  width: 20rem;
                  padding: 0.75rem;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 0.75rem;
                  position: absolute;
                  right: 1rem;
                  top: 3.563rem;
                  border-radius: 0.5rem;
                  border: 0.0625rem solid var(--neutrals-100);
                  background: var(--static-background, #fff);
                  box-shadow: 0 0.75rem 1rem -0.25rem rgba(58, 51, 57, 0.08),
                    0 0.25rem 0.375rem -0.125rem rgba(26, 29, 43, 0.03);
        
                  @include m.media('mobile580') {
                    display: none;
                  }
        
                  .profile_box {
                    display: flex;
                    padding: 0.5rem;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;
                    border-radius: 0.75rem;
                    background-color: c.$white;
                    gap: 0.5rem;
        
                    .inner_wrapper {
                      display: flex;
                      padding: 0.5rem;
                      justify-content: space-between;
                      align-self: stretch;
                      border-radius: 0.75rem;
                      gap: 0.5rem;
                    }
                  }
        
                  .switch_profile {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    align-self: stretch;
        
                    .box {
                      display: flex;
                      width: 4.375rem;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      gap: 2px;
        
                      .name {
                        display: flex;
                        width: 2rem;
                        height: 2rem;
                        padding: 0.35rem 0;
                        justify-content: center;
                        align-items: center;
                        border-radius: 12.5rem;
                      }
                    }
                  }
        
                  .logout_btn {
                    display: flex;
                    padding: 0.5rem 0;
                    align-items: center;
                    gap: 0.25rem;
                    align-self: stretch;
                  }
                }
        
                .switch_profile {
                  display: flex;
                  align-items: flex-start;
                  gap: 1rem;
                  align-self: stretch;
        
                  .box {
                    display: flex;
                    width: 4.375rem;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 0.125rem;
        
                    .name {
                      display: flex;
                      width: 2rem;
                      height: 2rem;
                      padding: 0.35rem 0;
                      justify-content: center;
                      align-items: center;
                      border-radius: 12.5rem;
                    }
                  }
                }
        
                .logout_btn {
                  display: flex;
                  padding: 0.5rem 0;
                  align-items: center;
                  gap: 0.25rem;
                  align-self: stretch;
                }
    }
}
.name_box {
  display: flex;
  width: 3rem;
  height: 3rem;
  padding: 0.625 0;
  justify-content: center;
  align-items: center;
  border-radius: 12.5rem;
  background: var(--primary-100);
}