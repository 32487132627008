@use '../../../styles/abstracts/mixins' as m;
%carousel_image_style {
  width: 22.2rem !important;
  height: 8rem !important;
  border-radius: 0.56375rem;
}

.offer_carousel_image {
  @extend %carousel_image_style;
  cursor: pointer;

  img {
    @extend %carousel_image_style;
    object-fit: fill;
  }
}

.cards-carousel-component {
  .offer-slider-container {
    width: 94%;
    display: block;
    margin: 0 auto;

    .offer-image {
      @extend .offer_carousel_image;
    }

    .make-disable {
      @extend %carousel_image_style;
      opacity: 0.6;
      cursor: default;
      pointer-events: none;
    }
    .slick-slider {
      .button {
        border-radius: 1.125rem;
        background: var(--neutrals-900, #10111a);
      }
    }
  }

  %slick-slider-arrow-image {
    background-image: url('./../../../assets/icons/white-chevron-left.svg') !important;
    border-radius: 1.563rem;
    background-color: var(--neutrals-900);
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: center;
  }

  .card-slider-container {
    width: 100%;
    display: block;
    align-items: center;
    margin: 0 auto;
    overflow-x: auto;

    @include m.media('mobile') {
      width: 94%;
    }

    .offer-image {
      @extend .offer_carousel_image;
    }
  }

  .slick-prev {
    @extend %slick-slider-arrow-image;
  }
  .slick-prev:hover {
    @extend %slick-slider-arrow-image;
  }
  .slick-next {
    @extend %slick-slider-arrow-image;
    background-image: url('./../../../assets/icons/white-chevron-right.svg') !important;
  }
  .slick-next:hover {
    @extend %slick-slider-arrow-image;
    background-image: url('./../../../assets/icons/white-chevron-right.svg') !important;
  }

  .slick-prev::before,
  .slick-next::before {
    content: ' ' !important;
    color: var(--neutrals-900) !important;
  }

  .slick-track {
    width: auto !important;
  }

  .slick-arrow .slick-next {
    width: 1.25rem;
    height: 1.25rem;
  }

  .slick-slide {
    text-align: center;
    transform: scale(0.95);
    opacity: 1;
  }

  .slick-list {
    overflow-x: auto;
  }
}
