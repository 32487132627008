@use './../../../styles/abstracts/colors' as c;
.footer {
  .store-icons {
    color: var(--neutrals-400);
    div {
      display: flex;
      align-items: center;
      column-gap: 0.625rem;
    }
  }
}
