@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/colors' as c;
.navlink_container {
  padding: 0.75rem 0.75rem 0.75rem 0.5rem;
  &:hover {
    background-color: var(--primary-100);
  }

  .navlink_dropdown_container {
    display: flex;
    align-items: center;
    min-height: 1.5rem;

    .icon-class-rotate-down {
      transform: rotate(180deg);
      transition: transform 500ms;
    }
    .icon-class-rotate-up {
      transform: rotate(360deg);
      transition: transform 500ms;
    }
  }
}

.dropdown_list {
  padding: 0.5rem 1rem 0.5rem 3.5rem;
  &:hover {
    background-color: var(--primary-100);
  }
}
