@use '../../../styles/abstracts/colors' as c;

.accordion-container {
  & .accordion__item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & .accordion__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    & .accordion__content {
      &.hide {
        display: none;
      }
    }
  }
}
