@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;

.alert {
  @apply relative top-0 right-0;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: v.$fs-12;

  box-shadow: 0 0.25rem 0.5rem -0.125rem rgba(26, 29, 43, 0.1),
    0 0.125rem 0.25rem -0.125rem rgba(26, 29, 43, 0.06);
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 0.75rem;
    &__icon--position {
      align-items: flex-start;
    }
    &__outerbox {
      inline-size: 80%;
    }
    &__box {
      inline-size: 80%;
    }
  }
  &--success {
    background-color: c.$success-25;
    color: c.$success-500;
    .font-style {
      color: c.$success-600;
    }
  }
  &--error {
    background-color: c.$red-25;
    color: c.$red-500;
    .font-style {
      color: c.$red-600;
    }
  }
  &--warning {
    background-color: c.$warning-25;
    color: c.$warning-500;
    .font-style {
      color: c.$warning-600;
    }
  }
  &--info {
    background-color: var(--neutrals-25);
    color: var(--neutrals-500);
    .font-style {
      color: var(--neutrals-600);
    }
  }
  .btn--style {
    padding: 0rem 0.875rem !important;
  }

  .close--icon {
    @apply absolute top-1 bottom-0 right-0 px-4 py-3;
    color: var(--neutrals-900);
  }
  .icon__box {
    @apply absolute top-0.5 bottom-2 right-5 px-4 py-3;
  }
  .action__btn {
    @apply flex justify-end gap-4;
    padding: 0 2rem;
  }
}
