.loader,
.loader:after {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
}
.loader {
  display: inline-block;
  margin-right: 0.5rem;
  padding: 8px;
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid var(--primary-200);
  border-right: 4px solid var(--primary-200);
  border-bottom: 4px solid var(--primary-200);
  border-left: 4px solid var(--primary-600);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.2s infinite linear;
  animation: load8 1.2s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
