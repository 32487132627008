//Screen Breakpoints
$break-laptoplg: 1640px;
$break-abovelaptop: 1590px;
$break-laptoplg1440: 1440px;
$break-laptop: 1366px;
$break-laptop1365: 1365px;
$break-belowlaptop: 1280px;
$break-MDPI: 1180px;
$break-desktop: 1024px;
$break-ipadproportrait: 991px;
$break-ipad880: 880px;
$break-ipad: 767px;
$break-mobile: 640px;
$break-mobile580: 580px;
$break-mobilesmall: 448px;
$break-mobilemini: 320px;


//font-family
$font-family: 'Noto Sans', sans-serif;

//font-size
$fs-40: 2.5rem;
$fs-36: 2.25rem;
$fs-32: 2rem;
$fs-30: 1.875rem;
$fs-28: 1.75rem;
$fs-24: 1.5rem;
$fs-22: 1.375rem;
$fs-20: 1.25rem;
$fs-18: 1.125rem;
$fs-16: 1rem;
$fs-14: 0.875rem;
$fs-12: 0.75rem;
$fs-10: 0.625rem;

//font-weight
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$fw-800: 800;
$fw-700: 700;
$fw-600: 600;
$fw-500: 500;
$fw-400: 400;

$font-style-italic: italic;
