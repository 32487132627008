@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './base/typography';
@import './base/loader';
@import './base/scrollbar';
@import './base/layout';

@import './themes/theme';
@import '~react-tooltip/dist/react-tooltip.css';

@import 'slick-carousel/slick/slick-theme.css';
@import 'slick-carousel/slick/slick.css';
@import 'react-date-picker/dist/DatePicker.css';
@import 'react-calendar/dist/Calendar.css';
