@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;

.group-container {
  width: 100%;
  .button-group {
    display: flex;
    gap: 0.5rem;

    .btn--md {
      border-radius: 2.5rem;
      min-width: 10rem;
    }

    .active-btn {
      background-color: var(--primary-100);
      border: 1px solid var(--primary-600);
    }

    .inactive-btn {
      background-color: var(--neutrals-100);
    }
  }
}
