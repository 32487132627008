@use '../../../styles/abstracts/colors' as c;

.chip[data-type='orange'] {
  --chip-background-color: #{var(--primary-50)};
  --chip-border-color: #{var(--primary-500)};
  --chip-label-color: #{var(--primary-700)};
}

.chip[data-type='gray'] {
  --chip-background-color: #{var(--neutrals-50)};
  --chip-border-color: #{var(--neutrals-500)};
  --chip-label-color: #{var(--neutrals-700)};
}

.chip {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: var(--chip-background-color);
  border: 0.0625rem solid var(--chip-border-color);
  .chip__label,
  .chip__left-icon {
    color: var(--chip-label-color);
  }
  .chip__right-icon {
    color: var(--chip-border-color);
  }
  &--sm,
  &--md {
    padding: 0.0625rem 0.1875rem;
  }
  &--lg,
  &--xl {
    padding: 0.1875rem;
  }
  &--icon-only {
    &.chip--sm,
    &.chip--md,
    &.chip--xl {
      padding: 0.1875rem;
    }
    &.chip--lg {
      padding: 0.3125rem;
    }
  }
}
