@use '../../../styles/abstracts/colors' as c;
.au_date_selector_container {
  .react-date-picker__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border-width: 1px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    border-color: var(--neutrals-400);
    position: relative;
  }

  .react-date-picker--disabled {
    background-color: white;
    border-radius: 0.75rem;
    .react-date-picker__wrapper {
      background: c.$gray-100;
    }
  }
  .react-date-picker {
    width: 100%;
  }
  .react-date-picker__inputGroup__input {
    &:invalid {
      background: none;
    }
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: c.$orange-100;
  }
  .react-calendar__tile--active {
    color: c.$orange-600;
  }
}

.react-date-picker__clear-button {
  display: none;
}

.cross-icon-show .react-date-picker__clear-button {
  display: block;
}
