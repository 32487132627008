@use '../../../styles/abstracts/mixins' as m;
@use '../../../styles/abstracts/colors' as c;
.sidebar-wrapper-active[data-placement='top'] {
  --top: 0;
  --height: 50%;
  --width: 100vw;
}
.sidebar-wrapper-active[data-placement='left'] {
  --left: 0;
  --width: 460px;
  --height: 100%;
}
.sidebar-wrapper-active[data-placement='right'] {
  --right: 0;
  --width: 460px;
  --height: 100%;
  &.extended {
    --width: auto;
  }
}
.sidebar-wrapper-active[data-placement='bottom'] {
  --left: 0;
  --right: 0;
  --width: 100vw;
  --height: 50%;
}
.sidebar-wrapper-active {
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  top: 0;
  margin-top: 5rem;
  z-index: 1000;
  .side-drawer-wrapper
  {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    height: calc(100% - 4rem);
  }
  .upper-section{
    background-color: var(--neutrals-50);
    align-items: center;
    display: flex;
    height: 4.5rem;
    padding: 1rem;
  }

  .mask {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    .sidebar-right-left {
      position: absolute;
      bottom: 0;
      top: var(--top);
      right: var(--right);
      left: var(--left);
      height: var(--height);
      background-color: c.$white;
      animation: showsidebarrightleft 0.3s ease-out forwards;

      @keyframes showsidebarrightleft {
        from {
          width: 0;
        }
        to {
          width: var(--width);
        }
      }
      .sidebar-content {
        position: relative;
        height: 100%;
        overflow-y: auto;
        .sidebar-header {
          z-index: 1000;
          position: absolute;
          top: 1rem;
          right: 1rem;
        }
      }
    }
    .sidebar-top-bottom {
      position: absolute;
      bottom: 0;
      top: var(--top);
      right: var(--right);
      left: var(--left);
      height: var(--height);
      background-color: c.$white;
      animation: showsidebartopbottom 0.3s ease-out forwards;

      @keyframes showsidebartopbottom {
        from {
          height: 0;
          width: 100vw;
        }
        to {
          height: var(--height);

          width: 100vw;
        }
      }
      .sidebar-content {
        .sidebar-header {
          position: absolute;
          top: 0.875rem;
          right: 1.375rem;
        }
      }
    }
  }
}
.sidebar-wrapper-inactive {
  display: none;
}
@include m.media('mobile') {
  .sidebar-wrapper-active {
    .mask {
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      .sidebar-right-left {
        position: absolute;
        bottom: 0;
        top: var(--top);
        right: var(--right);
        left: var(--left);
        height: var(--height);
        background-color: c.$white;
        animation: showsidebarrightleft 0.3s ease-out forwards;

        @keyframes showsidebarrightleft {
          from {
            width: 0;
          }
          to {
            width: 100vw;
          }
        }
        .sidebar-content {
          .sidebar-header {
            position: absolute;
            top: 0.875rem;
            right: 1.375rem;
          }
        }
      }
      .sidebar-top-bottom {
        position: absolute;
        bottom: 0;
        top: var(--top);
        right: var(--right);
        left: var(--left);
        height: var(--height);
        background-color: white;
        animation: showsidebartopbottom 0.3s ease-out forwards;

        @keyframes showsidebartopbottom {
          from {
            height: 0;
            width: 100vw;
          }
          to {
            height: var(--height);

            width: 100vw;
          }
        }
        .sidebar-content {
          .sidebar-header {
            position: absolute;
            top: 0.875rem;
            right: 1.375rem;
          }
        }
      }
    }
  }
}
