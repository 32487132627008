@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.transactions_container {
  display: flex;
  gap: 1rem;
  @include m.media('ipad880') {
    flex-direction: column;
  }

  .transactions_container_section {
    display: flex;
    flex-direction: column;
    background-color: c.$white;
    border-radius: 0.75rem;
    position: relative;
    flex: 1;

    .transactions_title_section {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
    }

    .transactions_table {
      height: 100%;
      .transactions_table_header {
        background-color: var(--gray-200);
        height: 2.75rem;
        display: flex;
        flex-direction: row;

        .table_header_section {
          background-color: c.$gray-200;
          height: 2.75rem;
          display: flex;
          flex-direction: row;

          p {
            padding-left: 1rem;
            align-self: center;
          }
        }
      }

      .transactions_table_rows {
        display: flex;
        flex-direction: column;
        height: 100%;
        .table_row {
          display: flex;
          flex-direction: row;
          height: 4.5rem;
          cursor: pointer;

          .row_section {
            padding-left: 1rem;
            align-self: center;
            flex-direction: row;
          }

          .row-section_description {
            display: flex;
            flex-direction: column;
            min-width: 14.2rem;
            padding-left: 1rem;
            align-self: center;
          }
        }
      }
    }

    .dashboard_transactions_footer {
      display: flex;
      gap: 1.5rem;
      justify-content: center;
      padding: 1rem 0;
      flex: 1;
    }
  }
}
