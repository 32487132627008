.sidebar-layout-container {
  .layout-sidebar {
    width: 20vw;
    min-width: 200px;
  }
  main {
    max-height: 94vh;
    overflow: scroll;
  }
  nav {
    position: absolute;
    top: -45px;
    left: 10px;
    height: calc(100vh - 20px);
    width: 100%;
    border-radius: 10px;
    background-color: var(--sidebar-bg);
    li.nav-link {
      border-radius: 4px;
      margin-bottom: 8px;
      &.selected {
        background-color: var(--sidebar-color);
        a {
          color: #fff;
        }
      }
      &:hover {
        background-color: var(--sidebar-selected-bg);
        a {
          color: var(--text-color);
        }
      }
    }
    a {
      color: var(--sidebar-color);
    }
  }
}
