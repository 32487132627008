@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.sidebar_wrapper_common_style {
  position: relative;
  width: 14.5rem;
  overflow-y: auto;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: c.$white;
  border: solid 0.0625rem c.$gray-200;
  border-bottom-right-radius: 0.75rem;
  box-sizing: border-box;
  @include m.media('mobile580') {
    display: none;
  }

  .navlink_heading {
    margin: 0.75rem 0;
    @include m.media('mobile580') {
      display: none;
    }
  }
}

@mixin side_bar_btn() {
  position: absolute;
  background-color: c.$white;
  z-index: 10;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 7.5rem;
  border: 0.062rem solid c.$gray-300;
}

.side_bar_container {
  position: relative;
  padding-top: 1.5rem;
  // height: 100vh;

  .side_bar_close_btn {
    @include side_bar_btn();
    top: 1.4rem;
    left: 13.5rem;
    @include m.media('mobile580') {
      display: none;
    }
    cursor: pointer;
  }

  .side_bar_open_btn {
    @include side_bar_btn();
    top: 1.4rem;
    left: 3.5rem;
    cursor: pointer;
    @include m.media('mobile580') {
      display: none;
    }
  }
}
// make generic
.navbar_assistance_container {
  border-top: 0.0625rem solid c.$gray-200;
  padding: 0.75rem;
  position: sticky;
  background-color: c.$white;
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;

  &_box {
    border-radius: 0.75rem;
    border: 0.0625rem solid c.$gray-200;
    padding: 0.75rem;
    display: flex;
    align-items: center;
  }
}

.navbar_assistance_closed_container_box {
  border-radius: 0.75rem;
  border: 0.0625rem solid c.$gray-200;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

@mixin sidebar_list($height) {
  height: calc(100vh - $height);
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebar_list_expanded {
  @include sidebar_list(15rem);
}

.sidebar_list_closed {
  @include sidebar_list(13.5rem);
}

.sidebar_wrapper_open {
  @extend .sidebar_wrapper_common_style;
  width: 14.5rem;
}
.sidebar_wrapper_close {
  @extend .sidebar_wrapper_common_style;
  width: 4.5rem;
}

.sidebar_wrapper_overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  background: rgba(0, 0, 0, 0.37);
  z-index: 99;
  box-sizing: border-box;
  @include m.media('mobile580') {
    display: block;
  }

  .sidebar_wrapper_open_mob {
    position: absolute;
    width: 14.5rem;
    height: 100vh;
    overflow-y: auto;
    left: 0;
    opacity: 1;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: c.$white;
    padding: 5rem 0 4.275rem 0;
    box-sizing: border-box;
    display: none;
    z-index: 99999;
    @include m.media('mobile580') {
      display: block;
      transition: width 0.2s linear;
    }
  }

  .sidebar_wrapper_close_mob {
    display: none;
    width: 0;
  }
}

.sidebar_wrapper::-webkit-scrollbar {
  display: none;
}
