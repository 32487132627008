.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(26, 29, 43, 0.3);
  backdrop-filter: blur(3px);
}
.modal-content {
  min-width: 20.5rem;
  background: #ffffff;
  box-shadow: 0px 1.25rem 1.5rem -0.25rem rgba(26, 29, 43, 0.08),
    0 0.5rem 0.5rem -0.25rem rgba(26, 29, 43, 0.03);
  border-radius: 0.75rem;
  position: relative;
  .close-icon {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
  }
}
