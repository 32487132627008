@use '../../../styles/abstracts/colors' as c;

.spinner-container {
  position: relative;
  display: inline-block;
  z-index: 99999;
  &.icon {
    width: 2.375rem;
    height: 2.375rem;
    .loader { 
      width: 2.375rem;
      height: 2.375rem;
    }
    .spinner__icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      color: var(--primary-600);
    }
  }
  .loader {
    margin: 0;
    &--sm {
      width: 1rem;
      height: 1rem;
      padding: 0.25rem;
      border-width: 2px;
    }
    &--md {
      width: 1.5rem;
      height: 1.5rem;
      border-width: 3px;
    }
    &--lg {
      width: 2rem;
      height: 2rem;
      border-width: 3px;
    }
    &--xl {
      width: 4rem;
      height: 4rem;
      border-width: 4px;
    }
  }
}
