.slider {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.slide {
  width: 100%;
  max-width: 100%;
  height: 100%;
  transition: all ease-in-out 0.5s;
}
