@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;

.textarea {
  line-height: 1.5;
  label {
    @apply mb-1.5;
    color: var(--neutrals-700);
  }
  .label-icon {
    margin-top: 0.1875rem;
    color: var(--neutrals-400);
  }
  textarea::-ms-reveal,
  textarea::-ms-clear {
    display: none;
  }
  .input__wrapper {
    @apply flex border bg-white rounded-lg justify-center items-center py-2.5 px-3.5;
    border-color: var(--neutrals-400);
    position: relative;
  }
  textarea {
    resize: none;
    outline: none;
    color: var(--neutrals-900);
    cursor: auto;
    &:-ms-input-placeholder {
      color: var(--neutrals-300);
      font-weight: 400;
    }
    &::placeholder {
      color: var(--neutrals-300);
      font-weight: 400;
    }
  }

  &.textarea--disabled {
    .input__wrapper {
      background-color: var(--neutrals-100);
      textarea {
        color: var(--neutrals-400);
        -webkit-text-fill-color: var(--neutrals-400);
        background: transparent;
      }
    }
  }
  &.textarea--focused {
    .input__wrapper {
      border-color: var(--primary-500);
      box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
        0 0 0 0.25rem rgba(var(--primary-300), 0.5);
    }
  }
  &.textarea--error {
    color: c.$red-500;
    .input__wrapper {
      border-color: c.$red-500;
    }
    span.suffix-text {
      &:before {
        border-color: c.$red-500;
      }
    }
  }
  &.textarea--info {
    .input__additional-text {
      color: var(--neutrals-500);
    }
  }
  &.textarea--success {
    .input__additional-text {
      color: c.$success-green;
    }
  }
}
