@use '../../../styles/abstracts/colors' as c;

.dropdown.country-dropdown {
  .dropdown__wrapper {
    .dropdown__input {
      border: none;
      padding: 0;
      box-shadow: none;
    }
    .dropdown__list {
      left: -1rem;
      .check-mark {
        display: none;
      }
    }
    .dropdown__chevron {
      color: var(--neutrals-500);
      margin: 0 0.25rem;
    }
    .dropdown__value {
      .option__text {
        display: none;
      }
    }
  }
  &.disabled {
    .dropdown__chevron {
      display: none;
    }
    .dropdown__value {
      margin-right: 0.5rem;
    }
    .dropdown__input {
      background-color: transparent;
    }
  }
}
