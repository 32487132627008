.vertical-card {
  border-radius: 0.5rem;
  width: 11.429rem;
  height: 14.286rem;
  display: flex;
  flex-direction: row;

  .vertical-image {
    width: 11.429rem;
    height: 14.286rem;
    position: absolute;
  }

  .title-container {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .subtitle-container {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
