@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.casa-links-container {
  .account-summary-quick-link-container {
    background-color: c.$white;
    position: relative;
    margin-top: 0.3rem;

    h2 {
      color: c.$black;
    }
    .account-summary-card-container {
      border: 0.063rem solid var(--neutrals-200);
      display: flex;
      justify-content: space-between;
    }
    .account-summary-content-container {
      align-items: center;
    }
    .account-summary-text {
      color: var(--neutrals-900);
    }
    .account-summary-text-container {
      line-height: 1.25rem;
      color: var(--neutrals-500);
    }
  }
}
