// colors

$headerBg: #26282c;
$black: #000;
$white: #fff;
$lightwhite: #f5f5f5;
$pearlWhite: #fff2f0;
$blue: #00aaaa;
$lightBlue: #549cc7;
$purple: #6d3078;
$orange: #f37435;
$black2: #555;
$grey: #666666;
$warningYellow: #ffb400;
$boxBoderColor: #dddddd;
$boxHeadColor: #222121;
$titleColor: #404040;
$red: #d71a1a;
$darkGray: #3d3d3d;
$gray: #565656;
$rose-600: #e31b54;

// most used colors in application
$primary: #f37435;
$light-gray: #777777;
$input-text-color: #3f414d;

//orange
$orange-50: #fef8f5;
$orange-100: #fef1eb;
$orange-200: #fde3d7;
$orange-300: #fac7ae;
$orange-400: #f8ac86;
$orange-500: #f5905d;
$orange-600: #f37435;
$orange-700: #b65728;
$orange-800: #7a3a1b;

//yellow
$yellow-50: #fffaeb;

//pink
$pink-50: #fdf2fa;

//gray
$gray-25: #fcfcfd;
$gray-50: #f9f9fb;
$gray-100: #f2f2f4;
$gray-200: #e1e1e3;
$gray-300: #d1d2d5;
$gray-400: #a3a5aa;
$gray-500: #767780;
$gray-600: #575965;
$gray-700: #3d404d;
$gray-800: #222636;
$gray-900: #10111a;

//red
$red-25: #fffbfa;
$red-50: #fef3f2;
$red-100: #fee4e2;
$red-200: #fecdca;
$red-300: #fda29b;
$red-400: #f97066;
$red-500: #f04438;
$red-600: #d92d20;
$red-700: #b42318;
$red-800: #912018;

//success
$green-25: #f6fef9;
$green-50: #ecfdf3;
$green-400: #32d583;
$green-500: #12b76a;
$green-600: #039855;
$green-700: #027a48;

$blue-25: #f5fbff;
$blue-50: #eff8ff;
$blue-100: #d1e9ff;
$blue-500: #2e90fa;
$blue-700: #175cd3;
$blue-800: #1849a9;
$blue-light-700: #026aa2;
$blue-light-50: #f0f9ff;

$blue-gray-100: #eaecf5;
$blue-gray-600: #3e4784;
$blue-gray-800: #293056;

// component state based colors
$error-300: #fda29b;
$error-700: #b42318;
$error-red: #eb3d60;
$error-50: #fef3f2;
$success-green: #46ba58;

//success
$success-25: #f6fef9;
$success-500: #12b76a;
$success-600: #039855;
$success-700: #027a48;
$success-800: #05603a;

//Warning
$warning-25: #fffcf5;
$warning-50: #fffaeb;

$warning-500: #f79009;
$warning-600: #dc6803;
$warning-700: #b54708;

$purple-200: #e6d1e9;
$purple-500: #ae6dba;
$purple-600: #9a48a9;
$purple-800: #6d3078;
