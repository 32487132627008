@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.main-graph-container {
  display: flex;

  .graph {
    border-radius: 0 0.5rem 0.5rem 0;
    min-height: 2rem;
    height: 100%;
    display: flex;
  }

  .blue-graph {
    background-color: c.$blue-gray-600;
  }
  .rose-graph {
    background-color: c.$rose-600;
  }

  h3 {
    color: c.$blue-gray-100;
    margin-block: auto;
    padding-left: 0.75rem;
  }

  h2 {
    color: c.$white;
    margin-block: auto;
    padding-right: 0.75rem;
    margin-inline-start: auto;
  }

  .external-font {
    color: c.$blue-gray-800;
    margin-inline-start: inherit;
    padding-left: 0.375rem;
  }
}
