@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.au-alert-container {
  @mixin alert-css() {
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .alert {
      max-width: 50rem;
      display: flex;
      @include m.media('mobile') {
        max-width: 22rem;
      }
      min-width: 20rem;
      margin: 1rem auto;
      animation: fadeIn 2s;
    }
    z-index: 99999;
  }
  .top_right {
    position: fixed;
    top: 1rem;
    right: 1rem;
    @include alert-css();
  }
  .top_center {
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translate(-50%, 0%);
    @include alert-css();
  }
  .top_left {
    position: fixed;
    top: 1rem;
    left: 1rem;
    @include alert-css();
  }
}
