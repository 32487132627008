@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.bank_offer_container {
  display: flex;
  justify-content: center;

  .offer_container_card {
    width: 23.429rem;
    height: 3.571rem;
    border-radius: 0.5rem;
    position: relative;
    cursor: pointer;
    display: flex;

    img {
      width: 23.429rem;
      height: 3.571rem;
      border-radius: 0.5rem;
    }

    .title-container {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }
    .subtitle-container {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .offer_container_card_button {
      margin: 0.6rem;
      padding-left: 0.6rem;
      padding-right: 0.6rem;
    }

    .offer_container_card_close_badge {
      border: 0.064rem solid var(--neutral-300);
      background-color: c.$white;
      cursor: pointer;
      position: absolute;
      z-index: 9;
      top: -0.5rem;
      right: -0.5rem;
      padding: 0 !important;
    }
  }
}
