@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;

.dnone {
  display: none;
}
.heading_bar {
  h1 {
    font-weight: v.$fw-700;
    font-size: 1.125rem;
    color: c.$white;
  }
  p {
    font-weight: v.$fw-400;
    font-size: 0.875rem;
    color: var(--neutrals-50);
  }
}
