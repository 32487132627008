@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/colors' as c;

.btn {
  background-color: var(--primary-600);
  border-radius: 0.5rem;
  @apply rounded-lg font-medium text-white flex items-center justify-center gap-2;
  &:focus-visible {
    outline: 0;
  }
  &:disabled {
    @apply cursor-not-allowed;
    &.loading {
      .loader {
        margin: 0;
      }
    }
  }
  &--primary {
    &:hover {
      background-color: var(--primary-700);
    }

    &:focus {
      box-sizing: border-box;
      box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
        0 0 0 0.25rem rgba(var(--primary-300), 0.5);
    }
    &:disabled {
      background-color: var(--primary-200);
      &:hover {
        background-color: var(--primary-200);
      }
      &.loading {
        background-color: var(--primary-700);
        .loader {
          border-left-color: var(--primary-500);
        }
      }
    }
  }
  &--primary-error {
    background-color: c.$red-600;
    &:hover {
      background-color: c.$red-700;
    }

    &:focus {
      box-sizing: border-box;
      box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
        0 0 0 0.25rem c.$red-100;
    }
    &:disabled {
      background-color: c.$red-200;
      &:hover {
        background-color: c.$red-200;
        @apply text-white;
      }
    }
  }
  &--primary-error-50 {
    background-color: c.$red-50;
    color: c.$red-700;
    &:hover {
      background-color: c.$red-100;
    }
    &:focus {
      box-sizing: border-box;
      box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
        0 0 0 0.25rem c.$red-100;
    }
    &:disabled {
      background-color: c.$red-25;
      color: c.$red-300;
      &:hover {
        background-color: c.$red-25;
        color: c.$red-300;
      }
    }
  }

  &--secondary {
    color: var(--primary-600);
    background-color: transparent;
    border: 0.0625rem solid var(--primary-600);
    &:hover {
      background-color: var(--primary-100);
    }
    &:focus {
      box-sizing: border-box;
      border: 0.0625rem solid var(--primary-300);
      box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
        0 0 0 0.25rem rgba(var(--primary-300), 0.5);
    }
    &:disabled {
      color: var(--primary-300);
      border: 0.0625rem solid var(--primary-300);
      &:hover {
        background-color: transparent;
      }
    }
  }
  &--secondary-gray {
    color: var(--neutrals-700);
    background-color: transparent;
    border: 0.0625rem solid var(--neutrals-300);
    &:hover {
      background-color: var(--neutrals-50);
    }
    &:focus {
      border: 0.0625rem solid var(--neutrals-300);
      /* Shadow/xs focused 4px gray-100 */
      box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
        0 0 0 0.25rem var(--neutrals-100);
    }
    &:disabled {
      color: var(--neutrals-300);
      border: 0.0625rem solid var(--neutrals-200);
      &:hover {
        background-color: transparent;
      }
    }
  }
  &--secondary-error {
    color: c.$red-700;
    background-color: transparent;
    border: 0.0625rem solid c.$red-300;
    &:hover {
      background-color: c.$red-50;
    }
    &:focus {
      border: 0.0625rem solid c.$red-300;
      /* Shadow/xs focused 4px gray-100 */
      box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05),
        0 0 0 0.25rem c.$red-100;
    }
    &:disabled {
      color: c.$red-300;
      border: 0.0625rem solid c.$red-200;
      &:hover {
        background-color: transparent;
      }
    }
  }

  &--tertiary {
    color: var(--primary-600);
    background-color: transparent;
    @apply ease-linear transition-all;
    &:hover {
      color: var(--primary-700);
      background-color: var(--primary-100);
    }
    &:focus {
      color: var(--primary-700);
    }
    &:disabled {
      color: var(--primary-200);
      &:hover {
        color: var(--neutrals-300);
        background-color: transparent;
      }
    }
  }

  &--tertiary-gray {
    color: var(--neutrals-700);
    background-color: transparent;
    @apply ease-linear transition-all;
    &:hover {
      color: var(--neutrals-600);
      background-color: var(--neutrals-100);
    }
    &:focus {
      color: var(--neutrals-700);
    }
    &:disabled {
      color: var(--neutrals-300);
      &:hover {
        color: var(--neutrals-300);
        background-color: transparent;
      }
    }
  }

  &--tertiary-error {
    color: c.$red-700;
    background-color: transparent;
    @apply ease-linear transition-all;
    &:hover {
      color: c.$red-800;
    }
    &:disabled {
      color: c.$red-300;
      &:hover {
        color: c.$red-300;
      }
    }
  }

  &--xs {
    font-size: 0.75rem;
    line-height: 1.125rem;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
    gap: 0.25rem;
    .loader {
      border-width: 0.09375rem;
      width: 0.75rem;
      height: 0.75rem;
    }
  }
  &--sm {
    padding: 0.5rem 0.875rem;
  }
  &--md {
    padding: 0.625rem 1rem;
  }
  &--sm,
  &--md {
    @apply text-sm;
    .loader {
      border-width: 0.125rem;
      width: 1rem;
      height: 1rem;
    }
  }
  &--lg {
    padding: 0.625rem 1.125rem;
  }
  &--xl {
    padding: 0.75rem 1.25rem;
  }
  &--lg,
  &--xl {
    @apply text-base;
    .loader {
      border-width: 0.15625rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  &--icon-right {
    svg {
      order: 1;
    }
  }
  &--secondary,
  &--secondary-gray,
  &--secondary-error {
    &.btn {
      &--xs {
        padding: 0.1875rem 0.875rem;
      }
      &--sm {
        padding: 0.4375rem 0.875rem;
      }
      &--md {
        padding: 0.5625rem 1rem;
      }
      &--lg {
        padding: 0.5625rem 1.125rem;
      }
      &--xl {
        padding: 0.6875rem 1.25rem;
      }
    }
  }
  &--primary-underline {
    padding: 0;
    text-decoration: underline;
    background-color: transparent;
    color: var(--primary-600);
    &:hover {
      color: var(--primary-800);
      text-decoration: none;
    }

    &:focus {
      color: var(--primary-700);
    }
    &:disabled {
      color: var(--neutrals-300);
      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
  &--gray-underline {
    padding: 0;
    text-decoration: underline;
    background-color: transparent;
    color: var(--neutrals-700);
    &:hover {
      color: var(--neutrals-400);
      text-decoration: none;
    }

    &:focus {
      color: var(--neutrals-700);
    }
    &:disabled {
      color: var(--neutrals-300);
      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
  &--icon-only {
    &.btn {
      &--xs {
        padding: 0.4375rem;
      }
      &--sm {
        padding: 0.625rem;
      }
      &--md,
      &--lg {
        padding: 0.75rem;
      }
      &--xl {
        padding: 0.875rem;
      }
    }
  }
  span.btn__text {
    pointer-events: none;
  }
}
