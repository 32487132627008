@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.container_fluid {
  display: flex;

  .content_container {
    margin-right: 0.5rem;
  }
  .toggle_container {
    position: relative;
    border-radius: 0.75rem;
    padding: 0.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .toggle_container_sm {
    width: 2.25rem;
    min-width: 2.25rem;
    height: 1.25rem;
  }

  .toggle_container_disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  .toggle_container_md {
    width: 2.75rem;
    min-width: 2.75rem;
    height: 1.5rem;
  }

  .toggle_container_orange {
    background-color: var(--primary-600);
    &:hover {
      @include m.media('mobileAbove') {
        background-color: var(--primary-700);
      }
    }
    &:active {
      background: var(--primary-600);
      box-shadow: 0 0 0 0.25rem rgba(var(--primary-300), 0.5);
    }
  }

  .toggle_container_gray {
    background-color: var(--neutrals-200);
    &:hover {
      background-color: var(--neutrals-300);
    }
    &:active {
      background: var(--neutrals-200);
      box-shadow: 0 0 0 0.25rem rgba(var(--primary-300), 0.5);
    }
  }

  .knob_sm {
    width: 1rem;
    height: 1rem;
    background: #ffffff;
    box-shadow: 0 0.063rem 0.188rem rgba(26, 29, 43, 0.1),
      0 0.063rem 0.125rem rgba(26, 29, 43, 0.06);
    border: 0.063rem solid #ddd;
    border-radius: 50%;
    left: 0;
    transition: left 0.3s ease-out;
    &.active_sm {
      margin-left: auto;
    }
  }

  .knob_md {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    background: #ffffff;
    box-shadow: 0 0.063rem 0.188rem rgba(26, 29, 43, 0.1),
      0 0.063rem 0.125rem rgba(26, 29, 43, 0.06);
    border: 0.063rem solid #ddd;
    border-radius: 50%;
    transition: left 0.3s ease-out;
    &.active_md {
      margin-left: auto;
    }
  }
}
