@use '../../../styles/abstracts/colors' as c;

.pdf-modal {
  position: relative;
  cursor: pointer;
  .parellel-div {
    position: absolute;
    width: 100%;
    height: 90%;
  }
  embed {
    border-radius: 0.3rem;
    border: solid 1px var(--neutrals-200);
  }
}
