@use '../../../styles/abstracts/colors' as c;

.file-uploading-module {
  .upload-wrapper {
    border: solid 1px var(--neutrals-400);
    border-style: dashed;
    background-color: var(--neutrals-50);
    height: 9.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    border-radius: 0.5rem;
  }
  .uploaded-files-wrapper {
    margin: auto;
    height: 43vh;
    .uploaded-files {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      p {
        font-size: 0.875rem;
      }
    }
  }
}
