@use '../../../styles/abstracts/colors' as c;

.au-input-date {
  input[type='date'] {
    position: relative;
    cursor: pointer;

    &::before {
      position: absolute;
      content: attr(data-date);
      display: inline-block;
      color: c.$black;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      display: none;
    }
    &::-webkit-datetime-edit-ampm-field,
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-hour-field,
    &::-webkit-datetime-edit-millisecond-field,
    &::-webkit-datetime-edit-minute-field,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-second-field,
    &::-webkit-datetime-edit-week-field,
    &::-webkit-datetime-edit-year-field,
    &::-webkit-datetime-edit-text {
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      cursor: pointer;
      right: 0;
      color: c.$black;
      opacity: 1;
      height: 1.5rem;
      width: 1.5rem;
      background: url('../../../assets/icons/calender-gray.svg') no-repeat;
    }
  }
  input[type='date'][value=''] {
    &::before {
      color: var(--neutrals-300);
    }
  }
  input[type='date'][value='Invalid date'] {
    &::before {
      color: var(--neutrals-300);
    }
  }
}

.disabled-date-input {
  .input__wrapper,
  input {
    background-color: var(--neutrals-100) !important;
  }
}
