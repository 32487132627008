@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.recent-transactions {
  overflow: auto;

  .download-statement {
    justify-content: flex-end;
  }
  .transactions-container {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: grid;

    .transaction-headers {
      background-color: var(--neutrals-200);
      padding: 0.75rem 0 0.75rem;
      vertical-align: middle;
    }
  }
  .casa-summary-table {
    min-width: 7rem;

    .green {
      color: c.$success-600;
      font-weight: v.$fw-500;
    }

    .red {
      color: c.$red-700;
      font-weight: v.$fw-500;
    }
  }
}
.no-trans {
  .no-trans-title {
    text-align: left;
    color: var(--neutrals-400);
    padding-top: 1.5rem;
  }
}

.modal-container {
  gap: 0.75rem;
  max-width: 25rem;
  width: 100%;

  .popup-amount {
    align-items: center;
    .creditAmount {
      font-weight: v.$fw-700;
      font-size: v.$fs-24;
      line-height: 2rem;
      color: c.$success-700;
    }
    .debitAmount {
      font-weight: v.$fw-700;
      font-size: v.$fs-24;
      line-height: 2rem;
      color: c.$red-700;
    }
  }

  .pop-up-field {
    color: var(--neutrals-500);
  }

  .pop-up-value {
    color: var(--neutrals-900);
    white-space: pre-wrap;
  }
}
