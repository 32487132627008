.progress-bar-container {
  position: relative;
  height: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.75rem;
  background-color: #d1d2d5;
  overflow: hidden;
}

.progress-bar-fill__Healthy {
  height: 100%;
  background-color: #12b76a;
  transition: width 0.4s ease-in-out;
}
.progress-bar-fill__Moderate {
  height: 100%;
  background-color: #f79009;
  transition: width 0.4s ease-in-out;
}
.progress-bar-fill__Critical {
  height: 100%;
  background-color: #f04438;
  transition: width 0.4s ease-in-out;
}

.semi-circle-container {
  position: relative;
  height: 150px;
  display: flex;
  align-items: flex-end;
}
.start-index {
  text-align: center;
  transform: translate(60px, 20px);
}
.end-index {
  text-align: center;
  transform: translate(-75px, 20px);
}

.semi-circle-svg {
  transform: rotate(-360deg);
  transform-origin: center;
}

.semi-circle-background {
  fill: none;
  stroke: #d1d2d5; /* Background color */
  border: 5rem solid #ccc;
  stroke-linecap: round;
}

.semi-circle-progress {
  fill: none;
  stroke: #32d583; /* Progress color */
  transition: stroke-dashoffset 0.35s;
}

.semi-circle-progress__Healthy {
  fill: none;
  stroke: #32d583; /* Progress color */
  transition: stroke-dashoffset 0.35s;
}
.semi-circle-progress__Moderate {
  fill: none;
  stroke: #f79009; /* Progress color */
  transition: stroke-dashoffset 0.35s;
}
.semi-circle-progress__Critical {
  fill: none;
  stroke: #f04438; /* Progress color */
  transition: stroke-dashoffset 0.35s;
}
