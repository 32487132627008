@use '../../../styles/abstracts/colors' as c;

.inline-message[data-type='success'] {
  --background-color: #{c.$green-25};
  --highlight-color: #{c.$green-500};
  --title-color: #{c.$green-600};
  --message-color: #{c.$green-700};
}
.inline-message[data-type='error'] {
  --background-color: #{c.$red-25};
  --highlight-color: #{c.$red-500};
  --title-color: #{c.$red-600};
  --message-color: #{c.$red-700};
}
.inline-message[data-type='warning'] {
  --background-color: #{c.$warning-25};
  --highlight-color: #{c.$warning-500};
  --title-color: #{c.$warning-600};
  --message-color: #{c.$warning-700};
}
.inline-message[data-type='info'] {
  --background-color: #{c.$blue-25};
  --highlight-color: #{c.$blue-500};
  --title-color: #{c.$blue-800};
  --message-color: #{c.$blue-700};
}
.inline-message[data-type='secondary'] {
  --background-color: #{var(--neutrals-25)};
  --highlight-color: #{var(--neutrals-500)};
  --title-color: #{var(--neutrals-800)};
  --message-color: #{var(--neutrals-700)};
}
.inline-message {
  display: flex;
  gap: 0.25rem;
  padding: 0.5rem;
  max-width: 328px;
  border-radius: 0.25rem;
  background: var(--background-color);
  border-left: 1px solid var(--highlight-color);
  font-size: 0.75rem;
  &--lg {
    max-width: 480px;
    font-size: 0.875rem;
  }
  & .icon {
    color: var(--highlight-color);
  }
  & .title {
    font-weight: 500;
    color: var(--title-color);
  }
  & .message {
    font-weight: 400;
    color: var(--title-color);
  }
}
