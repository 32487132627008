.otp-input__box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  &.auto{
    height: auto;
  }
}

.resend-otp-btn {
  padding: 0;
  &:hover {
    background-color: transparent;
  }
}
