@use '../../../styles/abstracts/variables';
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

@mixin profileWrapperCss($mobileDisplay) {
  background-color: c.$white;
  position: relative;
  border-radius: 0.75rem;
  padding-top: 0.5rem;
  height: 100vh;

  @include m.media('mobile580') {
    display: $mobileDisplay;
  }

  .profile_wrapper_inner {
    display: flex;
    width: 22.5rem;
    height: 4rem;
    padding: 0px 1rem;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
  }
}

.top_navbar {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  padding: 0.625rem 1.5rem;
  display: flex;
  height: 5rem;
  background: var(
    --Gradient-Style-0,
    linear-gradient(294deg, var(--primary-800) 3.89%, var(--primary-600) 100%)
  );

  // background: var(--Gradient-Style-0, linear-gradient(294deg, #4D2454 3.89%, #9A48A9 100%));
  .left_content {
    display: flex;
    align-items: center;
    img {
      width: 3rem;
      height: 3rem;

      @include m.media('mobile580') {
        display: none;
      }
    }

    .hamburger-sidebar-icon {
      display: none;
      @include m.media('mobile580') {
        display: flex;
        padding-right: 0.5rem;
      }
    }

    .dashboard_toggle {
      padding-left: 1rem;
    }
  }
  .right_content {
    margin-left: auto;
    align-items: center;
    display: flex;
    .list_wrapper {
      display: flex;
      align-items: center;
      li {
        list-style-type: none;
      }
    }
    .profile_wrapper {
      z-index: 51;
      display: flex;
      width: 20rem;
      padding: 0.75rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
      position: absolute;
      right: 1rem;
      top: 4.363rem;
      border-radius: 0.5rem;
      border: 0.0625rem solid var(--neutrals-100);
      background: var(--static-background, #fff);
      box-shadow: 0 0.75rem 1rem -0.25rem rgba(58, 51, 57, 0.08),
        0 0.25rem 0.375rem -0.125rem rgba(26, 29, 43, 0.03);

      @include m.media('mobile580') {
        display: none;
      }

      .profile_box {
        display: flex;
        padding: 0.5rem;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 0.75rem;
        background-color: var(--primary-50);
        gap: 0.5rem;

        .inner_wrapper {
          display: flex;
          padding: 0.5rem;
          justify-content: space-between;
          align-self: stretch;
          border-radius: 0.75rem;
          background-color: var(--primary-50);
          gap: 0.5rem;
        }
      }

      .switch_profile {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;

        .box {
          display: flex;
          width: 4.375rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2px;

          .name {
            display: flex;
            width: 2rem;
            height: 2rem;
            padding: 0.35rem 0;
            justify-content: center;
            align-items: center;
            border-radius: 12.5rem;
          }
        }
      }
      .logout_btn {
        display: flex;
        padding: 0.5rem 0;
        align-items: center;
        gap: 0.25rem;
        align-self: stretch;
      }

      .name_box {
        display: flex;
        width: 3rem;
        height: 3rem;
        padding: 0.42rem 0;
        justify-content: center;
        align-items: center;
        border-radius: 12.5rem;
      }
    }

    .switch_profile {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      align-self: stretch;

      .box {
        display: flex;
        width: 4.375rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.125rem;

        .name {
          display: flex;
          width: 2rem;
          height: 2rem;
          padding: 0.35rem 0;
          justify-content: center;
          align-items: center;
          border-radius: 12.5rem;
        }
      }
    }
    .logout_btn {
      display: flex;
      padding: 0.5rem 0;
      align-items: center;
      gap: 0.25rem;
      align-self: stretch;
    }
  }

  .sidedrawer_mobile_bottom_sheet {
    display: none;
    @include m.media('mobile580') {
      display: flex;
    }

    .profile_header_container {
      display: flex;
      width: 22.5rem;
      height: 4rem;
      padding: 0 1rem;
      justify-content: space-between;
      align-items: center;
    }
    .profile_wrapper {
      @include profileWrapperCss(none);
    }

    .bottom_sheet_profile_wrapper {
      display: none;
      @include profileWrapperCss(block);
    }
    .sidebar-header {
      z-index: 1;
    }
  }
}

.sidebar-wrapper-active .mask .sidebar-top-bottom {
  bottom: 3rem;
  height: 30.5rem !important;
  border-radius: 0.75rem;
  overflow: scroll;
}
.name_box {
  display: flex;
  width: 3rem;
  height: 3rem;
  padding: 0.625 0;
  justify-content: center;
  align-items: center;
  border-radius: 12.5rem;
  background: var(--primary-100);
}

.notification {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  .notification_title_and_time_section {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
  }
}

.profile-switch-card {
  .radio {
    label {
      padding-left: 0;
    }
  }
}
