@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;

.input {
  font-size: v.$fs-14;
  line-height: 1.5;
  cursor: pointer;
  position: relative;
  label {
    @apply block mb-1.5;
    color: var(--neutrals-700);
  }
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
  .input__search__wrapper {
    @apply flex border bg-white rounded-lg justify-center items-center py-2.5 px-3.5;
    border-color: var(--neutrals-400);
    position: relative;
  }
  input {
    @apply w-full;
    outline: none;
    font-size: v.$fs-16;
    color: var(--neutrals-900);
    &:-ms-input-placeholder {
      color: var(--neutrals-300);
      font-weight: 400;
    }
    &::placeholder {
      color: var(--neutrals-300);
      font-weight: 400;
    }
  }
  &.input--p-icon,
  &.input--p-child,
  &.input--p-card {
    .prefix,
    .p-icon {
      margin-right: 0.75rem;
    }
  }
  &.input--p-child {
    .prefix {
      color: var(--neutrals-900);
    }
  }
  .p-icon,
  .s-icon {
    color: var(--neutrals-500);
  }
  &.input--p-card {
    .prefix {
      @apply flex items-center justify-center rounded;
      background-color: c.$white;
      width: 2rem;
      height: 1.5rem;
    }
  }
  &.input--s-text {
    span.suffix-text {
      padding-left: 10px;
      color: var(--neutrals-500);
      &:before {
        content: '';
        border-left: 1px solid var(--neutrals-400);
        position: absolute;
        height: 100%;
        top: 0;
        margin-left: -0.75rem;
      }
    }
  }
  &.input--disabled {
    .input__search__wrapper {
      background-color: var(--neutrals-100);
      input {
        color: var(--neutrals-400);
        -webkit-text-fill-color: var(--neutrals-400);
        background: transparent;
      }
    }
  }
  &.input--focused {
    .input__search__wrapper {
      border-color: var(--primary-500);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
        0px 0px 0px 4px rgba(var(--primary-300), 0.5);
    }
  }
  &.input--error {
    color: c.$red-500;
    .input__search__wrapper {
      border-color: c.$red-500;
    }
    span.suffix-text {
      &:before {
        border-color: c.$red-500;
      }
    }
  }
  &.input--info {
    .input__additional-text {
      color: var(--neutrals-500);
    }
  }
  &.input--success {
    .input__additional-text {
      color: c.$success-green;
    }
  }
  &.input--verified {
    .input__additional-text {
      color: c.$success-green;
    }
    color: c.$success-green;
  }

  .input__drawer {
    background: c.$white;
    border: 1px solid var(--neutrals-100);
    box-shadow: 0px 12px 16px -4px rgba(58, 51, 57, 0.08),
      0px 4px 6px -2px rgba(26, 29, 43, 0.03);
    border-radius: 0.5rem;
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 9999;
    height: 12.5rem;
    overflow: scroll;

    .data-wrapper {
      padding: 0.625rem 0.875rem;
      &:hover {
        background-color: var(--neutrals-100);
      }
    }
    .data_wrappera_active {
      background-color: var(--neutrals-100);
    }
  }

  .input_Drawewr_blank {
    background: c.$white;
    border: 1px solid var(--neutrals-100);
    box-shadow: 0px 12px 16px -4px rgba(58, 51, 57, 0.08),
      0px 4px 6px -2px rgba(26, 29, 43, 0.03);
    border-radius: 0.5rem;
    position: absolute;
    left: 0;
    padding: 0.625rem 0.875rem;
    right: 0;
    z-index: 9999;
    text-align: center;
  }
}
