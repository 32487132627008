@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.au-card-item-detail {
  display: flex;
  padding: 1rem;
  border: 1px solid var(--neutrals-200);

  .au-card-details {
    display: flex;

    @include m.media('ipadproportrait') {
      row-gap: 1rem;
    }
  }
}
