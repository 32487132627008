@use '../../../styles/abstracts/colors' as c;
@use '../../../styles/abstracts/mixins' as m;

.deposit-info-main-container {
  background-color: c.$white;
  .deposit-items-container {
    max-width: 100%;
    @include m.media('mobile') {
      display: flex;
      flex-direction: column;
    }
    @include m.media('ipad') {
      flex-wrap: wrap;
    }
  }
}
