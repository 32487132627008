@use '../../../styles/abstracts/mixins' as m;

.au_multiselect {
  max-width: 30rem;
  @include m.media('mobileAbove') {
    max-width: 30rem;
    min-height: 17rem;
  }

  .multiselect__item {
    padding: 0.3rem;
    cursor: pointer;
    &:hover {
      background-color: var(--neutrals-50);
      padding: 0.3rem;
    }
    &[aria-selected='true'] {
      background-color: var(--neutrals-100);
      padding: 0.3rem;
      .checkbox {
        border-color: var(--primary-600);
        color: var(--primary-600);
      }
    }
  }
  .multiselect__wrapper {
    position: relative;
  }

  .multiselect-list {
    align-items: center;
    display: block;
    max-height: 13rem;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .selected-ddn-value {
    display: flex;
    gap: 0.75rem;
    margin-top: 0.5rem;
  }
  .multiselect-dropdown-tags {
    display: flex;
    gap: 0.25rem;
    flex-wrap: wrap;
    overflow: scroll;
    max-height: 8rem;
  }
}
