@use '../../../styles/abstracts/variables' as v;
@use '../../../styles/abstracts/colors' as c;

@mixin toggleOption($bgColor, $textColor) {
  border-radius: 3.3125rem;
  cursor: pointer;
  background-color: $bgColor;
  flex: 1;
  height: 100%;
  color: $textColor;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle_options_container {
  border-radius: 2.375rem;
  display: flex;
  width: 9.375rem;
  height: 2.4rem;
  align-items: center;
  border: 0.13rem solid c.$white;
  background: c.$white;
  padding: 0.1rem;
}

.bank_toggle_active {
  transition: all 0.2s ease-in-out;
  @include toggleOption(c.$orange-600, c.$white);
}

.store_toggle_active {
  transition: all 0.2s ease-in-out;
  @include toggleOption(c.$purple-600, c.$white);
}

.toggle_inactive {
  transition: all 0.2s ease-in-out;
  @include toggleOption(c.$white, c.$gray-900);
}
